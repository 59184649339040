<template>
    <div>
        <app-header />
        <main>
            <section class="section section-lg">
                <div class="container">
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center">
                            <h3 class="display-3"><img src="hflogocom.png" class="img-center img-fluid w-50">
                                <span class="text-primary">Prese Kit</span>
                            </h3>
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <h2>Logos</h2>
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <b-img :src="require('@/assets/images/logo/hflogo.png')" class="ml-2" style="height:50px;" alt="hustleforge" />
                        </div>
                        <div class="col-lg-9 text-center pt-5">
                            <img src="hflogocom.png" class="img-center chatscreen">
                        </div>
                        <div class="col-lg-9 text-center pt-5">
                            <img src="hfdarklogo.png" class="img-center chatscreen">
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <h2>Screenshots</h2>
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <img src="profiles.png" class="img-center chatscreen">
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <img width="600px" src="experts.png" class="img-center chatscreen">
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <img src="communication.png" class="img-center chatscreen">
                        </div>
                    </div>
                    <div class="row row-grid justify-content-center">
                        <div class="col-lg-9 text-center pt-5">
                            <img src="search.png" class="img-center chatscreen">
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <app-footer />
    </div>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";
const db = getFirestore()

import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea, 
  BCard,
  BCardText,
  BCardActions,
  BAvatar,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppFooter from './AppFooter.vue'
import AppHeader from './AppHeader.vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormTextarea, 
        BCard,
        BCardText,
        BCardActions,
        BAvatar,
        ValidationProvider,
        ValidationObserver,
        AppFooter,
        AppHeader,
        BImg,
    },
    name: "home",
    title: 'Hustleforge',
    data: function(){
        return {
        }
    },
};
</script>
